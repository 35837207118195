
.body{
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height:1.7;
  color:#161616;
  background-color: #f3f3f3;
  display:grid;
  min-block-size: 100vh;
  min-block-size: 100dvh;
  grid-template-columns: 5rem 1fr ;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
  'sidebar header'
  'sidebar main'
  'sidebar footer';
}
.dashboard-sidebar{
  position: relative;
  grid-area:sidebar;
  background-color: #1d1d29;
  color:#fff
}
.dashboard-sidebar button {
  position:absolute;
  inset: 4.5rem -0.75rem auto auto;
  width: 1.5rem;
  aspect-ratio: 1;
  background-color: #fff;
  border:transparent;
  border-radius: 50%;
  cursor:pointer
}
.dashboard-header {
  grid-area:header;
  background-color: #fff;
  padding:1.25rem;
}

.dashboard-footer  {
  grid-area:footer;
  padding:1.25rem;
}

.dashboard-main {
  grid-area:main;
  padding:1.25rem;
}



.logo{
  display:flex;
  justify-content:center;
  font-size:3rem;
  line-height:1.3;
}
.NewNote form {
  padding-bottom: 15px;
}

.NewNote form textarea {
  height: 300px;
  font-size: 24px;
}

.note_holder{
  display: grid;
  height:100vh;
  width:80vw;
  grid-template-columns: 1fr;
  background-color: #d41818;

}
.pain_box{
  display:grid;
  flex-direction:column;
  height:5vh;
  grid-template-columns: 1fr;

}
.pain_levels{
  display: grid;
  flex-direction: row;
  width:30vw;
  height:20vh;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(5,1fr);
}


.box_pain{
  background-color: aquamarine;
  display: grid;
  width:15vw;
  min-height: 5vh;
  max-height: 5vh;
}
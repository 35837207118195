#root{
  /* width:100vw;
  background-color:brown ;
  height:100vh; */

}
/* html{
  background-color: brown;
  display:flex;
}
.App {
background-color: brown;
height:70vh;
width:100vw;
} */

/* .container{
  height: 100vh;
  background-color: black;
  display: flex;
} */

.App .navbar-brand {
  font-weight: bold;
}



body {

  font-family: Helvetica, Arial, sans-serif;
 }
 